<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block" id="modal-corr">
      <div class="modal-header">
        <h3>Corr. Pricer: {{ borrowerFullName !== "null null" ? borrowerFullName : "" }}</h3>
        <multiselect
          v-model="formData.rateSheetId"
          id="rateSheetOptionsField"
          class="custom-select"
          open-direction="bottom"
          :options="multiselect.rateSheetIds"
          :searchable="false"
          :show-labels="false" @select="switchRateSheet"
          placeholder="Rate sheet ID">
        </multiselect>
      </div>
      <div class="modal-body pb-0 mb-0">
        <div class="modal-body-container">
          <div class="modal-body-container__title">Scenario Builder</div>
          <div class="row mb-3">
            <div class="col-3">
              <div class="form-group">
                <label>Loan Type</label>
                <multiselect
                  v-model="formData.loanType"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="multiselect.loanTypes"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Select Purpose"
                  @select="toggleLoanType"/>
                <div class="error" v-if="errors['loanType']">
                  {{ errors['loanType'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Loan Purpose</label>
                <multiselect
                  v-model="formData.loanPurpose"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="multiselect.purposes"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Select Purpose"
                  :class="{errorField: errors.loanPurpose}"/>
                <div class="error" v-if="errors['loanPurpose']">
                  {{ errors['loanPurpose'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3" v-if="showVAUseTypeField">
              <div class="form-group">
                <label aria-label="property_type">VA Use Type</label>
                <multiselect
                  v-model="formData.VAUseType"
                  id="VAUseTypeField"
                  class="full-width w-100 custom-select"
                  track-by="value"
                  label="label"
                  open-direction="bottom"
                  :options="multiselect.VAUseTypeOptions"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="VA Use Type">
                </multiselect>
                <div class="error" v-if="errors['VAUseType']">
                  {{ errors['VAUseType'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3" v-if="showFundingFeeInput">
              <div class="form-group">
                <label aria-label="property_type">Funding Fee</label>
                <multiselect
                  v-model="formData.fundingFeeType"
                  id="fundingFeeTypeField"
                  class="full-width w-100 custom-select"
                  track-by="value"
                  label="label"
                  open-direction="bottom"
                  :options="multiselect.fundingFeeTypeOptions"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Funding Fee">
                </multiselect>
                <div class="error" v-if="errors['fundingFeeType']">
                  {{ errors['fundingFeeType'][0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label aria-label="property_type">Property Type</label>
                <multiselect
                  v-model="formData.propertyType"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="multiselect.propertyTypes"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Select Property" @select="selectPropertyType"
                  :class="{errorField: errors.propertyType}"/>
                <div class="error" v-if="errors['propertyType']">
                  {{ errors['propertyType'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3" v-if="formData.propertyType.toLowerCase().includes('condo')">
              <div class="form-group">
                <label>Property Attachment</label>
                <multiselect
                  v-model="formData.propertyAttachmentType"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="multiselect.propertyAttachment"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Property Attachment"/>
                <div class="error" v-if="errors['attachmentType']">
                  {{ errors['attachmentType'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label>Property Use</label>
                <multiselect
                  v-model="formData.propertyUse"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="multiselect.propertyUse"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="Property Use"
                  :class="{errorField: errors.propertyUse}"/>
                <div class="error" v-if="errors['propertyUse']">
                  {{ errors['propertyUse'][0] }}
                </div>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label for="numberOfUnits">Units</label>
                <input
                  v-model="formData.numberOfUnits"
                  id="numberOfUnits"
                  type="number"
                  min="1"
                  max="4"
                  class="form-control"/>
                <div class="error" v-if="errors['numberOfUnits']">
                  {{ errors['numberOfUnits'][0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-3">
              <div class="form-group">
                <label for="fieldPropertyValue">Property Value</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">$</span>
                  <input id="fieldPropertyValue"
                         class="form-control"
                         placeholder="Property Value"
                         v-model="formData.propertyValue"
                         v-mask="ui.mask"/>
                </div>
                <div class="error" v-if="errors['propertyValue']">
                  {{ errors['propertyValue'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="fieldAmount">Loan Amount</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">$</span>
                  <input id="fieldAmount"
                         placeholder="Loan Amount"
                         class="form-control"
                         v-model="formData.loanAmount"
                         v-mask="ui.mask"/>
                </div>
                <div class="error" v-if="errors['loanAmount']">
                  {{ errors['loanAmount'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3" v-if="formData.loanPurpose !== 'Purchase'">
              <div class="form-group">
                <label for="fieldAmount">2nd Loan Amount</label>
                <div class="input-with-icon mb-2">
                  <span class="icon">$</span>
                  <input id="field2ndAmount"
                         type="text"
                         class="form-control"
                         placeholder="Loan Amount"
                         v-model="formData.secondLoanAmount"
                         v-mask="ui.mask"/>
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="fieldFICO">FICO</label>
                <input id="fieldFICO"
                       v-model.number="formData.fico"
                       class="form-control"
                       placeholder="FICO"
                       type="number"/>
                <div class="error" v-if="errors['creditScore']">
                  {{ errors['creditScore'][0] }}
                </div>
              </div>
            </div>
            <div class="col-3" v-if="ltv > 80 && formData.loanType !== 'VA'">
              <div class="form-group">
                <label>MI Type</label>
                <multiselect
                  v-model="formData.mortgageInsuranceType"
                  :allow-empty="false"
                  :close-on-select="true"
                  :options="multiselect.miTypeOptions"
                  :searchable="false"
                  :show-labels="false"
                  placeholder="MI Type"/>
                <div class="error" v-if="errors['mortgageInsuranceType']">
                  {{ errors['mortgageInsuranceType'][0] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-9">
              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <label for="fieldZIP">Zip</label>
                    <input id="fieldZIP"
                           type="number"
                           class="form-control"
                           v-model="formData.zip"
                           @input="getState"
                           :class="{errorField: errors['zipCode']}"/>
                    <div class="error" v-if="errors['zipCode']">
                      {{ errors['zipCode'][0] }}
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>State</label>
                    <multiselect
                      v-model="formData.state"
                      :allow-empty="false"
                      :close-on-select="true"
                      :options="multiselect.states"
                      :searchable="false"
                      :show-labels="false"
                      placeholder="State"/>
                    <div class="error" v-if="errors['propertyState']">
                      {{ errors['propertyState'][0] }}
                    </div>
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>County</label>
                    <multiselect
                      v-model="formData.county"
                      :allow-empty="false"
                      :close-on-select="true"
                      :options="multiselect.countyOptions"
                      :searchable="false"
                      :show-labels="false"
                      placeholder="County"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="row">
                <div class="col-2">
                  <div class="form-group">
                    <label>Lock days</label>
                    <multiselect
                      v-model="formData.commitmentPeriod"
                      :allow-empty="false"
                      :close-on-select="true"
                      :options="multiselect.commitmentPeriodOptions"
                      :searchable="false"
                      :show-labels="false"/>
                    <div class="error" v-if="errors['commitmentPeriod']">
                      {{ errors['commitmentPeriod'][0] }}
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label>DTI > 40%</label>
                    <multiselect
                      v-model="formData.debtToIncome"
                      :allow-empty="false"
                      :close-on-select="true"
                      :options="multiselect.debtToIncomeOptions"
                      :searchable="false"
                      :show-labels="false"/>
                    <div class="error" v-if="errors['debtToIncome']">
                      {{ errors['debtToIncome'][0] }}
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label>Exact Rate Type</label>
                    <multiselect v-model="formData.exactRateType"
                                 :allow-empty="false"
                                 :close-on-select="true"
                                 :options="multiselect.exactRateTypeOptions"
                                 :searchable="false"
                                 :show-labels="false"/>
                    <div class="error" v-if="errors['debtToIncome']">
                      {{ errors['debtToIncome'][0] }}
                    </div>
                  </div>
                </div>
                <div class="col-2" v-if="formData.exactRateType === 'Target Rate'">
                  <div class="form-group" >
                    <label for="ficoField">Target Rate</label>
                    <input type="number"
                           class="form-control"
                           id="ltvField"
                           placeholder="6.100"
                           v-model="formData.targetRate"
                           v-input-filled="formData.targetRate"/>
                    <div class="error" v-if="errors['targetRate']">
                      {{ errors['targetRate'][0] }}
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="form-group">
                    <label for="bps">CYP (BPS)</label>
                    <input type="number"
                           class="form-control"
                           id="bps"
                           v-model="formData.bps"
                           v-input-filled="formData.bps"/>
                    <div class="error" v-if="errors['bps']">
                      {{ errors['bps'][0] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-9 my-auto">
              <div class="d-flex w-100">
                <div class="form-check-square d-flex align-items-center w-50">
                  <input type="checkbox" class="form-check-input" v-model="formData.isFirstTimeHomeBuyer" id="fieldIsFirstTimeHomeBuyer">
                  <label for="fieldIsFirstTimeHomeBuyer" class="mb-0">First Time Home Buyer</label>
                </div>
              </div>

            </div>
            <div class="col-3 d-flex justify-content-end">
              <button class="btn bg-green" @click="getPropositions">
                Submit
              </button>
            </div>
          </div>
          <div class="alert alert-info mt-4" role="alert" v-if="formData.isFirstTimeHomeBuyer">
            Note: Income must be 100% of AMI or 120% of AMI in high cost areas in order to qualify for for FTHB waiver.
          </div>
          <div class="alert alert-warning mt-3" role="alert" v-if="isCustomRate">
            Selected custom rate: <b>{{ isNaN(rate) ? 0.0 : rate }}%</b>. Please get price on UWM or
            <a href="#" class="alert-link" @click="askForAssistance">Ask for assistance</a>.
          </div>
          <div class="alert alert-warning mt-3 text-center" v-if="validationErr">
            <b>The given data was invalid.</b>
          </div>
          <div class="alert alert-warning mt-3" role="alert" v-if="formData.bps">
            You are using Control Your Price
          </div>
          <div class="alert alert-warning mt-3 text-center" v-if="smWrong">
            <b>Something went wrong.</b>
            <p class="mt-1 mb-0">
              IT just received notification about this error - most likely we
              just downloaded new rates sheet by UWM and looks like there are
              some new adjustments or programs added or removed, so we have to
              update this Auto Quote tool to get data from correct rows &
              columns.
              <b>This should be fixed soon (~15-30 minutes).</b>
              Sorry for the inconvenience.
            </p>
          </div>
        </div>
        <div class="modal-body-container border-bottom-0" v-if="!ui.isSelectedProposition">
          <div v-if="!isGranted('ROLE_IREFI_AGENT')">
            <div class="modal-body-container__title mb-0">Rate Sheet</div>
            <div class="row d-flex align-items-end">
              <div class="col-8">
                <div class="rates" v-if="partners.length">
                  <button
                    class="rate"
                    v-for="(partner, index) in partners"
                    :key="partner + index"
                    @click="selectFilter(partner)"
                    :class="{ active: isSelectedFilter(partner) }">
                    {{ partner.name }}: {{ partner.comp }}
                  </button>
                </div>
              </div>
              <div class="col-2">
                <div class="form-group">
                  <label>Terms</label>
                  <multiselect
                    v-model="loanTerms"
                    :allow-empty="false"
                    :close-on-select="true"
                    :options="loanTermOptions"
                    :searchable="false"
                    :multiple="true"
                    :show-labels="false"
                    @input="updateFilter"
                    placeholder="Terms"
                  />
                </div>
              </div>
            </div>
          </div>
          <page-spinner v-if="ui.loading" />
          <div class="propositions" id="propositions" v-else>
            <div v-if="propositions.length">
              <div
                v-for="proposition in propositions"
                :key="proposition.id"
                :class="[proposition.visible ? 'proposition' : '']"
              >
                <div
                  class="proposition-header"
                  :class="{ active: proposition.showBody }"
                  @click="toggleProposition(proposition)"
                  v-if="proposition.visible"
                >
                  <div>
                    <div class="proposition__title">
                      {{ proposition.program }}
                      <span class="mi" v-if="proposition.mortgageInsurance">{{proposition.mortgageInsurance}}</span>
                    </div>
                    <div class="proposition__subtitle">
                      {{ proposition.lock }} day lock period
                    </div>
                  </div>
                  <div>
                    <img alt="" src="@/assets/icons/arrow-down.svg" />
                  </div>
                </div>
                <div class="proposition-body" v-if="proposition.showBody">
                  <table class="proposition-table">
                    <thead>
                      <tr>
                        <th style="width: 15%">Rate</th>
                        <th style="width: 15%">Base Price</th>
                        <th style="width: 30%">
                          Final price
                          <div class="subtitle">Rate / Amount</div>
                        </th>
                        <th style="width: 20%">P&I</th>
                        <th style="width: 30%">
                          MI
                          <div class="subtitle">Factor / Amount</div>
                        </th>
                        <th style="width: 20%">Payment</th>
                        <th style="width: 30%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(option, optionIdx) in proposition.options" :key="optionIdx">
                        <td class="color-blue">{{ optionIdx }}%</td>
                        <td class="color-blue">
                          {{ formatRate(option.base_price) }}
                        </td>
                        <td>
                          <div class="d-flex">
                            <div>{{ option.final_price_percent }}%</div>
                            <div class="ms-2">
                              / ${{ option.final_price_amount }}
                            </div>
                          </div>
                        </td>
                        <td class="color-blue">
                          {{ convertToCurrency(option.pi) }}
                        </td>
                        <td>
                          <div class="d-flex">
                            <div>{{ option.miFactor }}%</div>
                            <div class="ms-2">/ ${{ option.miAmount }}</div>
                          </div>
                        </td>
                        <td class="color-blue">
                          {{ convertToCurrency(option.payment) }}
                        </td>
                        <td style="
                            text-align: end;
                            padding-right: 14px;
                            padding-left: 10px;
                          ">
                          <button class="btn btn-outline-dark" @click="selectProposition(proposition, option, optionIdx)">
                            Select
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div v-else-if="!propositions.length && ui.preTextShow === false">
              <div class="proposition">
                <div class="proposition-header d-flex justify-content-center align-items-center">
                  <div class="proposition__title mb-0">
                    No Proposition Found
                  </div>
                </div>
              </div>
            </div>
            <div v-if="ui.preTextShow">
              <div class="proposition">
                <div class="proposition-header d-flex justify-content-center align-items-center">
                  <div class="proposition__title mb-0">
                    Check and submit form to get price.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0" v-else>
          <div class="modal-body-container__title">Selected Rate</div>
          <div class="selected-proposition proposition">
            <div class="proposition-header">
              <div>
                <div class="proposition__title">
                  {{ selectedProposition.proposition.program }}
                  <span class="mi" v-if="selectedProposition.proposition.mortgageInsurance">
                    {{ selectedProposition.proposition.mortgageInsurance }}
                  </span>
                </div>
                <div class="proposition__subtitle">
                  {{ selectedProposition.proposition.lock }} day lock period
                </div>
              </div>
            </div>
            <div class="selected-proposition-body proposition-body">
              <table class="proposition-table">
                <thead>
                  <tr>
                    <th style="width: 20%">Rate</th>
                    <th style="width: 30%">
                      Monthly Payment
                      <div class="subtitle">MI Amount</div>
                    </th>
                    <th style="width: 30%">
                      Premium
                      <div class="subtitle">Discount after comp.</div>
                    </th>
                    <th style="width: 30%"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="color-blue">
                      {{ selectedProposition.optionIdx }}%
                    </td>
                    <td>
                      <div class="color-green">
                        {{ convertToCurrency(selectedProposition.option.pi) }}
                      </div>
                    </td>
                    <td>
                      <div class="title">
                        {{ selectedProposition.option.final_price_percent }}%
                      </div>
                      <div class="subtitle">
                        ${{ selectedProposition.option.final_price_amount }}
                      </div>
                    </td>
                    <td style="
                        text-align: end;
                        padding-right: 14px;
                        padding-left: 10px;
                      ">
                      <button class="btn btn-outline-dark" @click="changeProposition">
                        Change
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer" v-if="ui.isSelectedProposition">
        <div class="d-flex">
          <button class="btn btn-cancel" @click="$emit('close')">Cancel</button>
          <button class="btn bg-green ms-2" @click="submitProposition">
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import PageSpinner from "../../../components/pageSpinner";
import axios from "axios";
import PerfectScrollbar from "perfect-scrollbar";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const currencyMask = createNumberMask({
  prefix: '',
  allowDecimal: true,
  includeThousandsSeparator: true,
  allowNegative: false,
});

export default {
  name: "CorrPricerModal",
  components: { PageSpinner, Multiselect },
  props: {
    borrowerFullName: {
      type: String,
      required: false,
      default: "",
    },
    loanAmount: {
      required: false,
      default: "",
    },
    creditScore: {
      type: Number,
      required: false,
      default: 0,
    },
    state: {
      type: String,
      required: false,
      default: "",
    },
    propertyValue: {
      type: String,
      required: false,
      default: "",
    },
    propertyType: {
      type: String,
      required: false,
      default: "",
    },
    attachmentType: {
      type: String,
      required: false,
      default: "",
    },
    propertyUse: {
      type: String,
      required: false,
      default: "",
    },
    propertyZip: {
      type: String,
      required: false,
      default: "",
    },
    purpose: {
      type: String,
      required: false,
      default: "",
    },
    rate: {
      type: Number,
      required: false,
      default: 0.0,
    },
  },
  data() {
    return {
      formData: {
        loanType: '',
        loanAmount: this.loanAmount,
        secondLoanAmount: 0,
        propertyValue: "",
        fico: this.creditScore,
        lockDays: "",
        zip: "",
        state: "",
        selectedProduct: "",
        propertyType: "",
        loanPurpose: "",
        propertyUse: "",
        propertyAttachmentType: "",
        numberOfUnits: 1,
        county: "",
        fundingFeeType: null,
        VAUseType: null,
        mortgageInsuranceType: null,
        commitmentPeriod: 30,
        rateSheetId: 'current',
        debtToIncome: 'No',
        exactRateType: '',
        targetRate: null,
        isFirstTimeHomeBuyer: false,
      },
      multiselect: {
        loanTypes: [],
        miTypeOptions: [],
        countyOptions: [],
        fundingFeeTypeOptions: [],
        VAUseTypeOptions: [],
        propertyTypes: [],
        purposes: [],
        states: [],
        propertyUse: [],
        propertyAttachment: [],
        rateSheetIds: [],
        commitmentPeriodOptions: [15, 30, 45, 60],
        debtToIncomeOptions: ['Yes', 'No'],
        exactRateTypeOptions: [],
      },
      loanTermOptions: [30, 20, 15, 10],
      loanTerms: [30],
      product: { value: null, label: "All" },
      propositions: [],
      partners: [],
      selectedPartners: null,
      selectedProposition: null,
      selectedRateFilter: "",
      ui: {
        loading: false,
        isSelectedProposition: false,
        showAttachmentType: false,
        ps: null,
        psPositions: null,
        preTextShow: true,
        mask: currencyMask
      },
      validationErr: false,
      emptyErr: false,
      smWrong: false,
      errors: [],
      isCYP: false,
      bps: null
    };
  },
  computed: {
    selectedRateFilterCount() {
      return this.selectedRateFilter.length ? 1 : 0;
    },
    isCustomRate() {
      return !isNaN(this.rate) && this.rate > 0.0 && this.rate % 0.125 !== 0;
    },
    ltv() {
      let loanAmount = this.formData.loanAmount
      let propertyValue = this.formData.propertyValue

      if (typeof loanAmount === 'string' || loanAmount instanceof String) {
        loanAmount = loanAmount.replaceAll(',', '')
      }

      if (typeof propertyValue === 'string' || propertyValue instanceof String) {
        propertyValue = propertyValue.replaceAll(',', '')
      }

      return (loanAmount / propertyValue) * 100;
    },
    cltv() {
      let loanAmount = this.formData.loanAmount
      let secondAmount = this.formData.secondLoanAmount
      let propertyValue = this.formData.propertyValue

      if (typeof loanAmount === 'string' || loanAmount instanceof String) {
        loanAmount = loanAmount.replaceAll(',', '')
        loanAmount = Number.parseFloat(loanAmount)
      }

      if (typeof propertyValue === 'string' || propertyValue instanceof String) {
        propertyValue = propertyValue.replaceAll(',', '')
      }

      if (typeof secondAmount === 'string' || secondAmount instanceof String) {
        secondAmount = secondAmount.replaceAll(',', '')
        secondAmount = Number.parseFloat(secondAmount)
      }

      let amount = loanAmount + secondAmount
      return (amount / propertyValue) * 100;
    },
    selectedCount() {
      return this.loanTerms.filter((el) => el.selected === true).length;
    },
    showFundingFeeInput() {
      let purpose = this.formData.loanPurpose
      return (
        purpose === "VA Cash Out - Type I" ||
        purpose === "VA Cash Out - Type II" ||
        purpose === "VA IRRRL" || (purpose === 'Purchase' && this.formData.loanType === 'VA')
      );
    },
    showVAUseTypeField() {
      let purpose = this.formData.loanPurpose
      return (
        this.formData.loanType === 'VA'
        && purpose === 'Purchase' || purpose === "VA Cash Out - Type I" || purpose === "VA Cash Out - Type II"
      );
    },
  },
  methods: {
    askForAssistance() {
      let formData = new FormData();
      formData.append("borrowerName", this.borrowerFullName);
      formData.append("rate", this.rate);

      this.$http
        .post("/api/v1/loans/ask-for-assistance", formData)
        .then(() => {
          this.pushAlert(
            "success",
            "Request send successfully. We will contact you as soon as possible."
          );
        })
        .catch(() => {
          this.pushAlert(
            "error",
            "Something went wrong. Refresh page and try again, please."
          );
        });
    },
    isSelectedFilter(val) {
      return this.selectedPartners === val;
    },
    toggleProposition(proposition) {
      this.propositions = this.propositions.map((el) => {
        if (el === proposition) {
          el.showBody = !el.showBody;
        } else {
          el.showBody = false;
        }
        return el;
      });
    },
    tableFilterAppend() {
      return document.getElementById("table-filter");
    },
    handleResponseData(data) {
      this.multiselect.loanTypes = data.loanTypeOptions;
      this.formData.loanType = this.multiselect.loanTypes[0];
      this.toggleLoanType(this.formData.loanType)
      this.multiselect.purposes = data.loanPurposeOptions;
      this.multiselect.propertyTypes = data.propertyTypeOptions;
      // console.log(this.multiselect.propertyTypes)
      this.multiselect.states = data.states;
      this.multiselect.miTypeOptions = data.miTypeOptions;
      this.multiselect.fundingFeeTypeOptions = data.fundingFeeTypeOptions;
      this.multiselect.VAUseTypeOptions = data.VAUseTypeOptions;
      this.multiselect.propertyUse = data.propertyUseOptions;
      this.multiselect.propertyAttachment = data.propertyTypeAttachmentOptions;
      this.multiselect.exactRateTypeOptions = data.exactRateTypeOptions;

      // Property Use
      this.formData.propertyType = this.propertyType;
      this.formData.propertyAttachmentType = this.attachmentType;
      this.formData.propertyUse = this.propertyUse;

      const findPurpose = this.multiselect.purposes.find(
        (el) => el.toLowerCase === this.purpose.toLowerCase
      );
      if (this.purpose !== "" && findPurpose) {
        this.formData.loanPurpose = findPurpose;
      }

      this.formData.propertyValue = this.propertyValue;
      this.formData.zip = this.propertyZip;
    },
    toggleLoanType(val) {
      if (val === 'VA') {
        this.multiselect.purposes = ['Purchase', 'VA Cash Out - Type I', 'VA Cash Out - Type II', 'VA IRRRL'];
      } else {
        this.multiselect.purposes = ['Purchase', 'Refinance Rate/Term', 'Refinance Cash Out'];
      }
      this.formData.loanType = val
      this.formData.loanPurpose = this.multiselect.purposes[0]
    },
    getData() {
      this.ui.preTextShow = false;
      this.ui.loading = true;
      const getFormData = axios
        .get(process.env.VUE_APP_V_CRM_URL + "/api/v1/aqt/uwm/get-form-data")
        .then((response) => {
          const data = response.data;
          this.handleResponseData(data);
          this.getState();
        });
      this.getRateSheetIds();
      const getPartners = this.$http
        .get("/api/v1/corr-pricer/get-partners")
        .then((resp) => {
          this.partners = resp.data.partners;
          this.selectedPartners = this.partners[0];
        });

      Promise.allSettled([getFormData, getPartners]).finally(() => {
        this.ui.loading = false;
      });
    },
    getPropositions: function () {
      this.validationErr = false;
      this.errors = [];
      this.ui.preTextShow = false;
      this.smWrong = false;
      this.ui.ps.destroy();
      this.ui.loading = true;
      this.propositions = [];

      let formData = new FormData();
      formData.append("loanType", this.formData.loanType);
      formData.append("loanPurpose", this.formData.loanPurpose);
      formData.append("loanAmount", this.formData.loanAmount.replaceAll(',', ''));
      formData.append("secondLoanAmount", this.formData.secondLoanAmount.replaceAll(',', ''));
      formData.append("propertyValue", this.formData.propertyValue.replaceAll(',', ''));
      formData.append("propertyUse", this.formData.propertyUse);
      formData.append("propertyType", this.formData.propertyType);
      formData.append("attachmentType", this.formData.propertyAttachmentType);
      formData.append("numberOfUnits", this.formData.numberOfUnits);
      formData.append("propertyState", this.formData.state);
      formData.append("zipCode", this.formData.zip);
      formData.append("county", this.formData.county);
      formData.append("creditScore", this.formData.fico);
      formData.append("compensation", this.selectedPartners.comp);
      formData.append("minCompensation", this.selectedPartners.minComp);
      formData.append("maxCompensation", this.selectedPartners.maxComp);
      formData.append("VAUseType", this.formData.VAUseType ? this.formData.VAUseType.value : null);
      formData.append("fundingFeeType", this.formData.fundingFeeType ? this.formData.fundingFeeType.value : null);
      formData.append("mortgageInsuranceType", this.formData.mortgageInsuranceType);
      formData.append("rateSheetId", this.formData.rateSheetId);
      formData.append("commitmentPeriod", this.formData.commitmentPeriod);
      formData.append("exactRateType", this.formData.exactRateType);
      formData.append("targetRate", this.formData.targetRate);
      formData.append("debtToIncome", this.formData.debtToIncome);

      if (this.formData.bps) {
        formData.append("bps", this.formData.bps);
      }

      axios
        .post(process.env.VUE_APP_V_CRM_URL + `/api/v1/aqt/uwm/get-quote`, formData)
        .then((resp) => {
          this.propositions = [];
          for (const respKey in resp.data.programs) {
            this.propositions.push(resp.data.programs[respKey]);
          }
          this.updateFilter();
          this.ui.loading = false;
          const scrollbar = document.getElementById("modal-corr");
          this.ui.ps = new PerfectScrollbar(scrollbar, {
            useBothWheelAxes: false,
            suppressScrollX: true,
          });
          this.formData.rateSheetId = resp.data.rateSheetId;

          if (this.propositions.length) {
            this.$nextTick(() => {
              const propositionsScroll =
                document.getElementById("propositions");
              this.ui.psPositions = new PerfectScrollbar(propositionsScroll);
            });
          }
        })
        .catch((err) => {
          this.ui.loading = false;

          if (err.response.status === 422) {
            this.validationErr = true;
            this.errors = err.response.data.errors;
          }

          if (err.response.status === 400) {
            this.smWrong = true;
          }
        })
        .finally(() => {
          this.ui.loading = false;
        });
    },
    selectProposition(proposition, option, optionIdx) {
      this.ui.isSelectedProposition = true;
      this.selectedProposition = { proposition, option, optionIdx };
    },
    changeProposition() {
      this.ui.isSelectedProposition = false;
      this.selectedProposition = null;
    },
    submitProposition() {
      this.formData.propertyValue = this.formData.propertyValue.replaceAll(',', '')
      this.formData.loanAmount = this.formData.loanAmount.replaceAll(',', '')
      this.formData.secondLoanAmount = this.formData.secondLoanAmount.replaceAll(',', '')
      let note = this.generateNote(this.selectedProposition, this.formData)
      this.$emit("submit-proposition", this.selectedProposition, this.formData, note);
    },
    generateNote(val, formData) {
      let note = '';
      let isCredit = val.option.final_price < 0
      let finalPrice = Math.abs(val.option.final_price)

      note += `You are offering a ${val.option.term}-year fixed, for ${val.optionIdx}% with a `
      if (isCredit) {
        note += `(${this.convertToCurrency(finalPrice)}) credit.\n\n`
      } else {
        note += `${this.convertToCurrency(finalPrice)} discount fee.\n\n`
      }

      note += 'Origination:\n'
      note += 'Underwriting Fee: $1,395.00\n'
      if (isCredit) {
        note += `Lender Credit: (${this.convertToCurrency(finalPrice)})\n`
      } else {
        note += `Discount Fee: ${this.convertToCurrency(finalPrice)}\n`
      }

      let adjustedOriginationCharge = val.option.final_price + 1395;
      let adjustedOriginationChargeFormatted = this.convertToCurrency(Math.abs(adjustedOriginationCharge));
      if (isCredit) {
        if (adjustedOriginationCharge < 0) {
          note += `Adjusted Origination Charge: (${adjustedOriginationChargeFormatted})\n\n`
        } else {
          note += `Adjusted Origination Charge: ${adjustedOriginationChargeFormatted}\n\n`
        }
      } else {
        note += `Adjusted Origination Charge: ${this.convertToCurrency(finalPrice + 1395)}\n\n`
      }

      if (isCredit) {
        note += 'The fees will look like this on the Loan Estimate:\n\n'
      }

      note += 'Section A:\n'
      if (!isCredit) {
        let formattedFinalPrice = this.convertToCurrency(finalPrice)
        let priceAsNumber = Number.parseFloat((finalPrice / formData.loanAmount) * 100).toFixed(5)
        note += `Correspondent Discount Fee: ${formattedFinalPrice} (${priceAsNumber})\n`
      }
      note += 'Underwriting Fee: $1395.00\n'
      note += 'Wire Fee: $47.00\n'
      if (isCredit) {
        note += `Correspondent Lender Credit: ${this.convertToCurrency(finalPrice)}\n`
      }

      note += '\n\n'

      note += 'Section B:\n'
      note += 'Flood Certificate: $8\n'
      note += 'Tax Service: $85\n'

      if (formData.loanType === 'VA' && formData.state === 'TX') {
        note += 'Survey Fee: $600.00\n'
      }

      if (this.isGranted('ROLE_SD_COMP_PLAN')) {
        note += 'Third Party Processing: $1990\n'
      }

      if (this.showAttachmentType) {
        note += 'HOA Fee: $250.00\n'
      }

      if (formData.loanType === 'VA') {
        note += 'Pest Inspection: $300.00\n'
        if (formData.state === 'TX') {
          note += 'Survey Fee: $600.00\n'
        }
      }

      // if (val.option.fundingFee > 0) {
      //   note += `Funding fee: ${this.convertToCurrency(val.option.fundingFee)}\n`
      // }

      note += '\n'

      note += 'Section C + D:\n'
      note += 'Third Party fees\n\n'

      return note;
    },
    selectFilter(val) {
      this.selectedPartners = val;
      this.getPropositions();
    },
    selectRateFilter(val) {
      if (!this.selectedRateFilter.find((el) => el === val)) {
        this.selectedRateFilter.push(val);
      } else {
        this.selectedRateFilter = this.selectedRateFilter.filter((el) => el !== val);
      }
    },
    isSelectedRateFilter(val) {
      return this.selectedRateFilter.find((el) => el === val);
    },
    clearSelectedRateFilter() {
      this.selectedRateFilter = [];
    },
    selectPropertyType(val) {
      this.formData.propertyType = val
      this.ui.showAttachmentType = this.formData.propertyType.toLowerCase().includes("condo");
    },
    getState() {
      this.errors.zipCode = false;
      let zipCode = this.formData.zip;
      this.errorMsg = "";
      if (zipCode.toString().length < 5 || zipCode.toString().length > 5) {
        this.errorMsg =
          "This field must be formatted as a Zip Code. E.g: 48152";
        return 0;
      }
      axios
        .get(
          process.env.VUE_APP_V_CRM_URL +
            `/api/v1/us_zips/get-state-county/${zipCode}`
        )
        .then((response) => {
          this.multiselect.countyOptions = response.data.counties;
          this.formData.state = response.data.state;
          this.formData.county = response.data.county;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.ui.loadingState = false;
        });
    },
    updateFilter() {
      let visibleProgramsNumber = 0;
      for (let programKey in this.propositions) {
        if (Object.prototype.hasOwnProperty.call(this.propositions, programKey)) {
          let visible = true;

          if (
            this.loanTerms.length > 0 &&
            !this.loanTerms.includes(this.propositions[programKey].term)
          ) {
            visible = false;
          }

          if (this.product.label !== "All") {
            if (!this.propositions[programKey].program.includes(this.product.label)) {
              visible = false;
            }
          }

          this.propositions[programKey]["visible"] = visible;

          if (visible === true) {
            visibleProgramsNumber++;
          }
        }
      }

      if (visibleProgramsNumber === 1) {
        for (let programKey in this.propositions) {
          if (
            Object.prototype.hasOwnProperty.call(this.propositions, programKey)
          ) {
            if (this.propositions[programKey].visible === true) {
              this.propositions[programKey]["showOptions"] = true;
            }
          }
        }
      }
    },
    getRateSheetIds() {
      axios.get(process.env.VUE_APP_V_CRM_URL + `/api/v1/aqt/uwm/get-rate-sheet-id-list`)
        .then((response) => {
          this.multiselect.rateSheetIds = response.data.rateSheetIds
        })
        .catch((e) => {
          console.log(e)
        })
    },
    switchRateSheet(val) {
      this.formData.rateSheetId = val
      this.getPropositions()
    }
  },
  beforeMount() {
    this.getData();
  },
  beforeDestroy() {
    this.ui.ps.destroy();
    this.ui.ps = null;
    this.ui.psPositions = null;
  },
  mounted() {
    this.$nextTick(() => {
      const scrollbar = document.getElementById("modal-corr");
      this.ui.ps = new PerfectScrollbar(scrollbar, {
        useBothWheelAxes: false,
        suppressScrollX: true,
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.mi {
  color: #f89420;
  font-weight: 700;
}
.modal {
  &-block {
    position: relative;
    width: 900px;
    overflow: initial;
  }
  &-body {
    &-container {
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      margin-bottom: 18px;
      padding-bottom: 17px;
      &__title {
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
      }
      .form-group {
        label {
          color: #000000;
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 4px;
        }
        .form-control {
          min-height: 32px;
          max-height: 32px;
          border: 1px solid #d4d4d4;
          border-radius: 4px;
          background-color: #ffffff;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 14px;

          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          &[type="number"] {
            -moz-appearance: textfield;
          }
        }
        .input-with-icon {
          .icon {
            top: 50%;
            left: 10px;
            transform: translate(0, -40%);
          }
          input {
            padding-left: 25px;
            padding-top: 8px;
          }
        }
        &::v-deep .multiselect {
          width: auto;
          min-height: 32px;
          max-height: 32px;
          &.selected {
            .multiselect__tags {
              background-color: #f3f3f4;
            }

            .multiselect__single {
              background-color: #f3f3f4;
              color: #000000;
              font-size: 14px;
              letter-spacing: 0;
            }

            input {
              background-color: #f3f3f4;
            }
          }

          &.multiselect--active {
            .multiselect__tags {
              transition: all 0.3s;
              border: 1px solid rgba(38, 111, 229, 0.5);
              box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
            }
          }

          &__tag {
            background: none;
            padding: 4px 0 4px 0;
            color: #000;
            &-icon {
              display: none;
            }
          }

          &__tags {
            padding: 5px 20px 0 8px;
            transition: all 0.3s;
            border: 1px solid #d4d4d4;
            border-radius: 4px;
            background-color: #ffffff;
            min-height: 32px;
            max-height: 32px;
            &:hover {
              border: 1px solid rgba(38, 111, 229, 0.2);
              box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
            }
          }

          &__select {
            min-height: 32px;
            max-height: 32px;
            padding: 0;
            background: none;
            &:before {
              top: 15%;
              position: relative;
              right: -4px;
              color: #999;
              border: solid rgba(0, 0, 0, 0.46);
              margin-top: 4px;
              border-width: 0 1px 1px 0;
              display: inline-block;
              padding: 3px;
              content: "";
              transform: rotate(44deg);
            }
          }

          &__single {
            color: #000;
            margin-bottom: 0;
            background: none !important;
            padding-left: 0;
            @media screen and (max-width: 568px) {
              font-size: 9px;
            }
          }

          &__option {
            padding: 10px 12px;
            min-height: 36px;
            transition: all 0.3s;
            line-height: 20px;
            font-size: 14px;
            color: #000;
            @media screen and (max-width: 568px) {
              font-size: 9px;
            }

            &--highlight {
              background: #f3f3f3;
              line-height: 20px;
              font-size: 14px;
              color: #000;
              @media screen and (max-width: 568px) {
                font-size: 9px;
              }
            }
          }

          &__placeholder {
            padding: 0;
          }
        }
      }
      .rates {
        display: flex;
        overflow-y: auto;
        .rate {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: fit-content;
          padding: 0 10px;
          height: 30px;
          border: 1px solid #cccccc;
          border-radius: 18px;
          margin-right: 4px;
          color: #000000;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 21px;
          background: none;

          &.active {
            color: #ffffff;
            background-color: #000000;
          }
        }
      }
      .propositions {
        position: relative;
        max-height: 500px;
        margin-top: 18px;
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
      .proposition {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        }
        &-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px 20px;
          transition: all 0.3s;
          cursor: pointer;
          &.active {
            background-color: rgba(0, 0, 0, 0.1);
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            img {
              transition: all 0.3s;
              transform: rotate(-180deg);
            }
          }
        }
        &__title {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 22px;
          margin-bottom: 8px;
        }
        &__subtitle {
          color: rgba(0, 0, 0, 0.7);
          font-size: 14px;
          letter-spacing: 0;
          line-height: 16px;
        }
        &-table {
          width: 100%;
          thead {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            tr {
              padding: 14px 0;
              height: 60px;
              th {
                color: #000000;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 12px;
                vertical-align: top;
                padding-top: 15px;
                &:first-child {
                  padding-left: 24px;
                }
                .subtitle {
                  color: #000000;
                  font-size: 12px;
                  letter-spacing: 0;
                  line-height: 12px;
                  font-weight: normal;
                  margin-top: 8px;
                }
              }
            }
          }
          tbody {
            tr {
              height: 72px;
              td {
                &:first-child {
                  vertical-align: center;
                  padding-left: 24px;
                }
                &:nth-child(2) {
                  vertical-align: center;
                }
              }
              .color-green {
                color: #24a82f;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
              }
              .color-blue {
                color: #0056ff;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
              }
              .subtitle {
                color: #000000;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 14px;
              }
              .title {
                color: #000000;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                margin-bottom: 8px;
              }
              .btn-outline-dark {
                height: 32px;
                min-height: 32px;
                max-height: 32px;
                width: 84px;
                border: 1px solid rgba(0, 0, 0, 0.5);
                border-radius: 8px;
                color: rgba(0, 0, 0, 0.7);
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 14px;
              }
            }
          }
        }
      }
      .selected-proposition {
        border: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 70px;
        &-body {
          padding: 0 20px;
        }
      }
      .btn-update {
        height: 33px;
        width: 100%;
        border: 1px solid #4c4c4c;
        border-radius: 4px;
        color: #000000;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 14px;
      }
    }
  }
}
.filter-menu {
  display: flex;
  justify-content: flex-end;
  &-header {
    min-width: 105px;
    max-width: fit-content;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    border: 1px solid #cccccc;
    border-radius: 18px;
    background-color: #ffffff;

    span {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }

    &__filter-icon {
      margin-right: 8px;
    }
  }
  &-clear {
    border-left: 1px solid #cccccc;
    margin-left: 7.5px;
    cursor: pointer;
    img {
      margin-left: 5.5px;
    }
  }
  &-block {
    min-width: 100px;
    padding: 10px 0;
    text-align: start;
  }
  &-item {
    padding: 5px 10px;
    text-align: start;
    cursor: pointer;
    transition: all 0.3s;
    &.active {
      background: rgba(0, 0, 0, 0.05);
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}
.error {
  font-size: 12px;
  color: red;
}
.errorField {
  border-color: red!important;
  &::v-deep {
    .multiselect__tags {
      border-color: red!important;
    }
  }
}
</style>
